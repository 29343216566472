import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { updateUsername, getUserPosts, deletePost } from '../services/api';

function ProfilePage() {
  const { user, setUser } = useAuth();
  const [username, setUsername] = useState('');
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState('');

  const fetchUserPosts = useCallback(async () => {
    if (!user || !user._id) return;
    try {
      const userPosts = await getUserPosts(user._id);
      setPosts(userPosts);
    } catch (error) {
      console.error('Failed to fetch user posts:', error);
      setError('Failed to fetch user posts. Please try again.');
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setUsername(user.username || '');
      fetchUserPosts();
    }
  }, [user, fetchUserPosts]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user || !user._id || user.username) return;
    try {
      const updatedUser = await updateUsername(user._id, username);
      setUser(updatedUser);
      setError('');
    } catch (error) {
      console.error('Failed to update username:', error);
      setError('Failed to update username. Please try again.');
    }
  };

  const handleDeletePost = async (postId) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        await deletePost(postId);
        setPosts(posts.filter(post => post._id !== postId));
        setError('');
      } catch (error) {
        console.error('Failed to delete post:', error);
        setError('Failed to delete post. Please try again.');
      }
    }
  };

  if (!user) {
    return <div className="text-center py-8">Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-neon-green mb-8">Your Profile</h1>
      {error && <p className="text-neon-pink mb-4">{error}</p>}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h2 className="text-2xl font-semibold text-neon-blue mb-4">User Information</h2>
          <p className="mb-2"><span className="text-neon-pink">Email:</span> {user.email}</p>
          {user.username ? (
            <p className="mb-2"><span className="text-neon-pink">Username:</span> {user.username}</p>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4 mt-4">
              <div>
                <label htmlFor="username" className="block text-neon-blue mb-2">Set Username</label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full p-2 bg-hacker-gray text-white border border-neon-green rounded"
                />
              </div>
              <button type="submit" className="bg-neon-pink text-hacker-black px-4 py-2 rounded hover:bg-neon-blue transition-colors">
                Set Username
              </button>
            </form>
          )}
        </div>
        <div>
          <h2 className="text-2xl font-semibold text-neon-blue mb-4">Your Posts</h2>
          {posts.length === 0 ? (
            <p>You haven't created any posts yet.</p>
          ) : (
            <ul className="space-y-4">
              {posts.map(post => (
                <li key={post._id} className="border border-neon-green p-4 rounded">
                  <h3 className="text-lg font-semibold text-neon-green">{post.title}</h3>
                  <p className="text-sm text-neon-blue mt-1">{post.category}</p>
                  <div className="mt-2 flex space-x-2">
                    <Link 
                      to={`/post/${post.slug}`}
                      className="text-neon-blue hover:text-neon-green"
                    >
                      View/Edit
                    </Link>
                    {/* <Link 
                      to={`/edit-post/${post.slug}`}
                      className="text-neon-blue hover:text-neon-green"
                    >
                      Edit
                    </Link> */}
                    <button 
                      onClick={() => handleDeletePost(post._id)}
                      className="text-neon-pink hover:text-neon-red"
                    >
                      Delete
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;