// src/components/Header.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import LoginButton from './LoginButton';
import ProfileButton from './ProfileButton';
import { useAuth } from '../contexts/AuthContext';

function Header() {
  const { user } = useAuth();

  return (
    <header className="bg-hacker-black w-full px-6 sm:px-6 lg:px-8 ">
      <div className="max-w-6xl mx-auto flex justify-between items-center border-b border-neon-green pb-4 pt-2">
        <Link to="/" className="text-xl sm:text-2xl font-bold">
          <span className="unicorn-gradient">unicorn</span>
          <span className="text-neon-green neon-glow">status</span>
          <p className="text-xs sm:text-sm text-neon-blue italic">Hack the startup ecosystem</p>
        </Link>
        <nav className="flex items-center space-x-4">
          {user && <ProfileButton />}
          <LoginButton />
        </nav>
      </div>
    </header>
  );
}

export default Header;