// src/components/ProfileButton.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function ProfileButton() {
  const { user } = useAuth();

  if (!user) return null;

  return (
    <Link to="/profile" className="text-neon-blue hover:text-neon-green text-sm">
      Profile
    </Link>
  );
}

export default ProfileButton;


