
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the auth token in requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle token expiration
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      localStorage.removeItem('token');
      // You might want to trigger a re-login here or redirect to login page
      // For now, we'll just throw an error
      throw new Error('Session expired. Please login again.');
    }
    return Promise.reject(error);
  }
);

// export const loginUser = async (tokenData) => {
//   const response = await api.post('/auth/google-login', tokenData);
//   return response.data;
// };

export const fetchPosts = async (category, sortBy, page) => {
  try {
    const response = await api.get('/posts', {
      params: { category, sortBy, page, limit: 30 }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
};

export const fetchPostById = async (id) => {
  const response = await api.get(`/posts/${id}`);
  return response.data;
};

export const createPost = async (postData) => {
  try {
    const response = await api.post('/posts', postData);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error.response?.data || error.message);
    throw error.response?.data || error;
  }
};

export const fetchPostBySlug = async (slug) => {
  try {
    const response = await api.get(`/posts/${slug}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching post:', error);
    throw error;
  }
};

export const upvotePost = async (id) => {
  const response = await api.post(`/posts/${id}/upvote`);
  return response.data;
};

export const updateUsername = async (userId, username) => {
  const response = await api.put(`/users/${userId}/username`, { username });
  return response.data;
};

export const verifyToken = async (token) => {
  const response = await api.post('/auth/verify-token', { token });
  return response.data;
};

export const addComment = async (postId, content, parentCommentId = null) => {
  try {
    const response = await api.post('/comments', { postId, content, parentCommentId });
    return response.data;
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};

export const getComment = async (commentId) => {
  const response = await api.get(`/comments/${commentId}`);
  return response.data;
};

export const updateComment = async (commentId, content) => {
  const response = await api.put(`/comments/${commentId}`, { content });
  return response.data;
};

export const deleteComment = async (commentId) => {
  const response = await api.delete(`/comments/${commentId}`);
  return response.data;
};

export const upvoteComment = async (commentId) => {
  try {
    const response = await api.post(`/comments/${commentId}/upvote`);
    return response.data;
  } catch (error) {
    console.error('Error upvoting comment:', error);
    throw error;
  }
};

export const getUserPosts = async (userId) => {
  const response = await api.get(`/users/${userId}/posts`);
  return response.data;
};

export const deletePost = async (postId) => {
  const response = await api.delete(`/posts/${postId}`);
  return response.data;
};

export const updatePost = async (postId, updates) => {
  const response = await api.put(`/posts/${postId}`, updates);
  return response.data;
};

export default api;