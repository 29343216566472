import React, { useState } from 'react';

function SearchBar({ onSearch, placeholder }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchTerm);
  };

  return (
    <form onSubmit={handleSubmit} className="flex-grow mr-2 max-w-3xl">
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={placeholder}
        className="w-full p-2 bg-hacker-gray text-white border border-neon-green rounded scrollbar-hide focus:scrollbar-default"
      />
    </form>
  );
}

export default SearchBar;