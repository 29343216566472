import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

function AddContentButton({ category }) {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <Link
      to={`/add/${category.toLowerCase()}`}
      className="bg-neon-pink text-hacker-black px-4 py-2 mt-1 rounded hover:bg-neon-blue transition-colors text-sm self-start"
    >
      {isMobile ? '+' : `+ Add ${category}`}
    </Link>
  );
}

export default AddContentButton;