import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronUp } from 'react-feather';

function ContentItem({ item, viewMode }) {
  return (
    <div className={`bg-hacker-gray border border-neon-pink rounded-lg overflow-hidden ${viewMode === 'list' ? 'flex items-center' : 'flex flex-col'}`}>
      <Link 
        to={`/post/${item.slug}`} 
        className={`p-4 hover:bg-hacker-black transition-colors ${viewMode === 'list' ? 'flex-grow flex items-center' : 'block'}`}
      >
        <div className={`flex ${viewMode === 'list' ? 'items-center w-full' : 'flex-col'}`}>
          <div className={`flex items-center ${viewMode === 'list' ? 'w-3/4' : 'w-full justify-between mb-2'}`}>
            <h2 className={`font-bold text-neon-green truncate mr-2 ${viewMode === 'list' ? 'text-base text-xs md:text-lg' : 'sm:text-sm'}`}>{item.title}</h2>
            {item.imageUrl && (
              <img 
                src={item.imageUrl} 
                alt={item.title} 
                className="w-6 h-6 object-cover rounded-full flex-shrink-0" 
              />
            )}
          </div>
          {(viewMode === 'grid' || (viewMode === 'list' && window.innerWidth >= 940)) && (
            <p className="text-white text-sm mt-2 line-clamp-2">{item.description}</p>
          )}
        </div>
      </Link>
      <div className={`px-4 py-2 ${viewMode === 'list' ? 'flex items-center' : 'flex justify-between items-center border-t border-neon-pink'}`}>
        <a
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-neon-pink hover:underline text-sm"
        >
          Site
        </a>
        <div className="flex items-center text-neon-blue text-sm ml-4">
          <ChevronUp size={14} className="mr-1" />
          <span>{item.upvoteCount}</span> 
        </div>
      </div>
    </div>
  );
}

export default ContentItem;