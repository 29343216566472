import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { createPost, updateUsername } from '../services/api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Utility function for debouncing
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

function AddPostPage() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [body, setBody] = useState('');
  const [url, setUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [category, setCategory] = useState('');
  const [error, setError] = useState('');
  const [showUsernamePrompt, setShowUsernamePrompt] = useState(false);
  const [username, setUsername] = useState('');
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const { category: routeCategory } = useParams();

  useEffect(() => {
    if (routeCategory) {
      setCategory(routeCategory);
    }
  }, [routeCategory]);

  const fetchFavicon = useCallback(async (url) => {
    if (!url) return;

    try {
      const domain = new URL(url).hostname;
      const faviconUrl = `https://www.google.com/s2/favicons?domain=${domain}&sz=64`;
      setImageUrl(faviconUrl);
    } catch (error) {
      console.error('Failed to fetch favicon:', error);
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchFavicon = useCallback(debounce(fetchFavicon, 2000), [fetchFavicon]);

  useEffect(() => {
    debouncedFetchFavicon(url);
  }, [url, debouncedFetchFavicon]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if (!user) {
      navigate('/login');
      return;
    }
    if (!user.username) {
      setShowUsernamePrompt(true);
      return;
    }
    if (description.length > 150 || body.length > 700 || title.length > 50) {
      setError('Description or body exceeds character limit.');
      return;
    }
    try {
      const newPost = await createPost({ 
        title, 
        description, 
        body, 
        url, 
        imageUrl,
        category,
        username: user.username
      });
      console.log('New post created:', newPost);
      navigate(`/post/${newPost.slug}`);
    } catch (error) {
      console.error('Failed to create post:', error.response?.data || error.message);
      setError(error.response?.data?.message || 'Failed to create post. Please try again.');
    }
  };

  const handleSetUsername = async (e) => {
    e.preventDefault();
    try {
      console.log('Logging username and userid');
      console.log(user._id);
      console.log(username);
      const updatedUser = await updateUsername(user._id, username);
      setUser(updatedUser);
      setShowUsernamePrompt(false);
      handleSubmit(e);
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to set username. Please try again.');
    }
  };

  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-neon-green mb-4">Add New Post</h1>
      {error && <p className="text-neon-pink mb-4">{error}</p>}
      {showUsernamePrompt ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-hacker-gray p-6 rounded-lg">
            <h2 className="text-xl font-bold text-neon-green mb-4">Set Username</h2>
            <form onSubmit={handleSetUsername} className="space-y-4">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter username"
                className="w-full p-2 bg-hacker-black text-white border border-neon-green rounded"
                required
              />
              <button type="submit" className="bg-neon-pink text-hacker-black px-4 py-2 rounded hover:bg-neon-blue transition-colors">
                Set Username
              </button>
            </form>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="title" className="block text-neon-blue mb-2">Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              maxLength={50}
              className="w-full p-2 bg-hacker-gray text-white border border-neon-green rounded"
            />
          </div>
          <p className="text-neon-blue text-sm mt-1">{title.length}/50 characters</p>
          <div>
            <label htmlFor="description" className="block text-neon-blue mb-2">Short Description</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value.slice(0, 50))}
              required
              className="w-full p-2 bg-hacker-gray text-white border border-neon-green rounded"
              rows="3"
              maxLength={150}
            ></textarea>
            <p className="text-neon-blue text-sm mt-1">{description.length}/50 characters</p>
          </div>
          <div>
            <label htmlFor="body" className="block text-neon-blue mb-2">Post Body</label>
            <ReactQuill 
              value={body} 
              onChange={(value) => setBody(value.slice(0, 700))}
              theme="snow"
              className="bg-hacker-gray text-white"
            />
            <p className="text-neon-blue text-sm mt-1">{body.replace(/<[^>]*>/g, '').length}/700 characters</p>
          </div>
          <div>
            <label htmlFor="url" className="block text-neon-blue mb-2">URL</label>
            <input
              type="url"
              id="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              required
              className="w-full p-2 bg-hacker-gray text-white border border-neon-green rounded"
            />
          </div>
          {imageUrl && (
            <div>
              <label className="block text-neon-blue mb-2">Favicon Preview</label>
              <img src={imageUrl} alt="Favicon" className="w-16 h-16 object-contain" />
            </div>
          )}
          <div>
            <label htmlFor="category" className="block text-neon-blue mb-2">Category</label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
              className="w-full p-2 bg-hacker-gray text-white border border-neon-green rounded"
            >
              <option value="">Select a category</option>
              <option value="Directories">Directories</option>
              <option value="Tools">Tools</option>
              <option value="News">News</option>
              <option value="Resources">Resources</option>
              <option value="Startups">Startups</option>
              <option value="Funding">Funding</option>
              <option value="Jobs">Jobs</option>
            </select>
          </div>
          <button 
            type="submit" 
            className="bg-neon-pink text-hacker-black px-4 py-2 rounded hover:bg-neon-blue transition-colors"
            disabled={description.length > 150 || body.replace(/<[^>]*>/g, '').length > 700 || title.length > 50}
          >
            Create Post
          </button>
        </form>
      )}
    </div>
  );
}

export default AddPostPage;