import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { fetchPostBySlug, updatePost, addComment, upvotePost, upvoteComment, updateUsername } from '../services/api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CommentSection from '../components/CommentSection';
import { ChevronUp, ExternalLink, Edit, Save } from 'react-feather';

function PostPage() {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedBody, setEditedBody] = useState('');
  const [showUsernamePrompt, setShowUsernamePrompt] = useState(false);
  const [username, setUsername] = useState('');
  const { slug } = useParams();
  const { user, loading: authLoading, setUser } = useAuth();

  useEffect(() => {
    const loadPost = async () => {
      try {
        const fetchedPost = await fetchPostBySlug(slug);
        setPost(fetchedPost);
        setEditedBody(fetchedPost.body);
      } catch (err) {
        setError('Failed to load post');
      } finally {
        setLoading(false);
      }
    };

    loadPost();
  }, [slug]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveEdit = async () => {
    try {
      const updatedPost = await updatePost(post._id, { body: editedBody });
      setPost(updatedPost);
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update post:', error);
    }
  };

  const handleAddComment = async (parentCommentId = null, content) => {
    if (!user?.username) {
      setShowUsernamePrompt(true);
      return;
    }
    try {
      await addComment(post._id, content, parentCommentId);
      const updatedPost = await fetchPostBySlug(slug);
      setPost(updatedPost);
    } catch (error) {
      console.error('Failed to add comment:', error);
    }
  };

  const handleUpvotePost = async () => {
    try {
      const updatedPost = await upvotePost(post._id);
      setPost(updatedPost);
    } catch (error) {
      console.error('Failed to upvote post:', error);
    }
  };

  const handleUpvoteComment = async (commentId) => {
    try {
      await upvoteComment(commentId);
      const updatedPost = await fetchPostBySlug(slug);
      setPost(updatedPost);
    } catch (error) {
      console.error('Failed to upvote comment:', error);
    }
  };

  const handleSetUsername = async (e) => {
    e.preventDefault();
    try {
      const updatedUser = await updateUsername(user._id, username);
      setUser(updatedUser);
      setShowUsernamePrompt(false);
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to set username. Please try again.');
    }
  };

  if (loading) return <div className="flex justify-center items-center h-screen"><div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-neon-green"></div></div>;
  if (error) return <div className="text-center py-8 text-neon-pink">{error}</div>;
  if (!post) return <div className="text-center py-8">Post not found</div>;

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <article className="bg-hacker-gray border border-neon-pink rounded-lg p-6 mb-8 shadow-lg">
        <div className="flex flex-col md:flex-row justify-between items-start mb-6">
        <a 
  href={post.url} 
  target="_blank" 
  rel="nofollow noopener noreferrer" 
  className="hover:underline"
>
  <h1 className="text-2xl md:text-3xl font-bold text-neon-green mb-4 md:mb-0 flex-grow">
    {post.title}
  </h1>
</a>
          {post.imageUrl && (
            <img 
              src={post.imageUrl} 
              alt={post.title} 
              className="w-16 h-16 md:w-16 md:h-16 object-cover rounded-full ml-4 border-2 border-neon-blue"
            />
          )}
        </div>
        
        <p className="text-white text-sm md:text-base mb-6">{post.description}</p>
        
        <div className="flex flex-wrap items-center mb-6 text-xs md:text-sm">
          <span className="text-neon-blue mr-4 text-xs">Category: {post.category}</span>
          <span className="text-neon-blue mr-4 text-xs">Posted by: {post.username || 'Anonymous'}</span>
          <span className="text-neon-blue text-xs">{new Date(post.createdAt).toLocaleDateString()}</span>
        </div>

   {/* Check if authLoading is false AND user and post.user exist before accessing _id */}
   {!authLoading && user && post.user && user._id === post.user._id && (
          <button
            onClick={handleEditToggle}
            className="bg-neon-blue text-hacker-black px-4 py-2 rounded mb-4 flex items-center"
          >
            {isEditing ? <Save size={16} className="mr-2" /> : <Edit size={16} className="mr-2" />}
            {isEditing ? 'Cancel Edit' : 'Edit Post'}
          </button>
        )}
        {isEditing ? (
          <div>
            <ReactQuill 
              value={editedBody} 
              onChange={setEditedBody}
              theme="snow"
              className="bg-hacker-gray text-white mb-4"
            />
            <button onClick={handleSaveEdit} className="bg-neon-green text-hacker-black px-4 py-2 rounded mr-2 flex items-center">
              <Save size={16} className="mr-2" />
              Save
            </button>
          </div>
        ) : (
          <div>
            {post.body ? (
              <div 
                dangerouslySetInnerHTML={{ __html: post.body }} 
                className="text-white mb-6 prose prose-invert max-w-none text-sm md:text-sm"
              />
            ) : (
              <p className="text-white mb-6">No content available.</p>
            )}
          </div>
        )}
        
        <div className="flex flex-wrap justify-between items-center mt-6">
  <button
    href={post.url}
    target="_blank"
    rel="noopener noreferrer"
    className="text-neon-pink hover:text-neon-blue transition-colors flex items-center px-2 py-1 text-sm" // Adjusted classes
  >
    <ExternalLink size={14} className="mr-1" /> {/* Smaller icon */}
    Visit Site
  </button>
  <button onClick={handleUpvotePost} className="text-neon-green hover:text-neon-blue flex items-center">
    <ChevronUp size={16} className="mr-1" />
    ({post.upvotes.length})
  </button>
</div>
      </article>

      {showUsernamePrompt && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-hacker-gray p-6 rounded-lg max-w-sm w-full">
            <h2 className="text-xl font-bold text-neon-green mb-4">Set Username</h2>
            <form onSubmit={handleSetUsername} className="space-y-4">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter username"
                className="w-full p-2 bg-hacker-black text-white border border-neon-green rounded"
                required
              />
              <button type="submit" className="w-full bg-neon-pink text-hacker-black px-4 py-2 rounded hover:bg-neon-blue transition-colors">
                Set Username
              </button>
            </form>
          </div>
        </div>
      )}

      <CommentSection 
        post={post}
        onAddComment={handleAddComment} 
        onUpvoteComment={handleUpvoteComment}
      />
    </div>
  );
}

export default PostPage;