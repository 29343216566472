// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './contexts/AuthContext';
import HomePage from './pages/HomePage';
import PostPage from './pages/PostPage';
import InfoPage from './pages/InfoPage';
import AddPostPage from './pages/AddPostPage';
import ProfilePage from './pages/ProfilePage';
import Header from './components/Header';
import Footer from './components/Footer';


const GOOGLE_CLIENT_KEY = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function App() {
  return (
   <GoogleOAuthProvider 
      clientId={GOOGLE_CLIENT_KEY}
      scopes={['openid', 'profile', 'email']}
      >
      <AuthProvider>
        <Router>
          <div className="min-h-screen bg-hacker-black text-white">
            <Header />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/post/:slug" element={<PostPage />} />
              <Route path="/add/:category" element={<AddPostPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/info" element={<InfoPage />} />
            </Routes>
          </div>
          <Footer></Footer>
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;