import React, { useState, useEffect, useRef } from 'react';
import Navigation from '../components/Navigation';
import SearchBar from '../components/SearchBar';
import ContentList from '../components/ContentList';
import AddContentButton from '../components/AddContentButton';
import { fetchPosts } from '../services/api';

function HomePage() {
  const [activeTab, setActiveTab] = useState('Directories');
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortBy, setSortBy] = useState(() => localStorage.getItem('sortBy') || 'hot');

  const latestRequest = useRef(null);

  useEffect(() => {
    const loadContent = async () => {
      const requestKey = `${activeTab}-${sortBy}-${page}`;

      if (latestRequest.current === requestKey) {
        return;
      }
      latestRequest.current = requestKey;

      setIsLoading(true);
      setError(null);

      try {
        const data = await fetchPosts(activeTab.toLowerCase(), sortBy, page);

        setContent(data.posts); // Update content directly

        setHasMore(data.currentPage < data.totalPages);
      } catch (error) {
        console.error('Error loading content:', error);
        setError('Failed to load content. Please try again.');
        setHasMore(false); // Set hasMore to false on error
      } finally {
        setIsLoading(false);
      }
    };

    // Reset and load content when activeTab, sortBy, or page changes
    setContent([]);
    setPage(1);
    setHasMore(true);
    loadContent();
  }, [activeTab, sortBy, page]);

  useEffect(() => {
    localStorage.setItem('sortBy', sortBy);
  }, [sortBy]);

  const handleSearch = (term) => {
    console.log('Searching for:', term);
  };

  const loadMore = () => {
    if (hasMore && !isLoading) {
      setPage(prev => prev + 1);
    }
  };

  return (
    <div className="max-w-6xl md:mx-auto py-4 mx-6 sm:mx-auto">
      <Navigation activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="flex justify-between items-center mb-4">
        <SearchBar onSearch={handleSearch} placeholder={`Search ${activeTab.toLowerCase()}...`} />
        <AddContentButton category={activeTab.toLowerCase()} />
      </div>

      {/* Always show ContentList */}
      <ContentList
        content={content}
        isLoading={isLoading}
        error={error}
        loadMore={loadMore}
        hasMore={hasMore}
        category={activeTab.toLowerCase()}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
    </div>
  );
}

export default HomePage;