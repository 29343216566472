import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-hacker-black w-full px-6 sm:px-6 lg:px-8 ">
      <div className="max-w-6xl mx-auto flex justify-between items-center border-t border-neon-green pb-4 pt-2">
        <Link to="/info" className="text-xs sm:text-xs">
          <span className="text-neon-green">/info</span>
        </Link>

        <p className="text-xs sm:text-xs text-neon-green">
        /© unicornstatus 2024
        </p> 
      </div>
    </footer>
  );
}

export default Footer;


