import React from 'react';

const tabs = ['Directories', 'Tools', 'News', 'Resources', 'Startups', 'Jobs', 'Funding'];

function Navigation({ activeTab, setActiveTab }) {
  return (
    <nav className="flex space-x-4 mb-6 overflow-x-auto">
      {tabs.map((tab) => (
        <button
          key={tab}
          onClick={() => setActiveTab(tab)}
          className={`px-3 py-2 rounded-md text-sm font-medium ${
            activeTab === tab
              ? 'bg-neon-green text-hacker-black'
              : 'text-neon-blue hover:bg-hacker-gray'
          } transition-colors`}
        >
          {tab}
        </button>
      ))}
    </nav>
  );
}

export default Navigation;