import React, { useState, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { ChevronUp } from 'react-feather';

function Comment({ comment, onReply, onUpvote, level = 0 }) {
  const [isReplying, setIsReplying] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleReply = () => {
    if (replyContent.trim() === '') return;
    onReply(comment._id, replyContent);
    setIsReplying(false);
    setReplyContent('');
  };

  return (
    <div className={`ml-${level > 0 ? 4 : 0} relative`}>
      {level > 0 && (
        <div
          className="absolute left-0 top-0 bottom-0 w-px bg-neon-blue opacity-30"
          style={{ left: '-1rem' }}
        />
      )}
      <div className="border-l-2 border-transparent pl-4 py-2">
        <p className="text-white text-sm mb-1">{comment.content}</p>
        <div className="flex justify-between items-center text-xs">
          <p className="text-neon-blue opacity-40 text-xs-2">{comment.user?.username || 'Anonymous'}</p>
          <div>
            <button onClick={() => onUpvote(comment._id)} className="text-neon-green hover:text-neon-blue mr-2">
              <ChevronUp size={16} className="mr-1" />({comment.upvotes?.length || 0})
            </button>
            <button onClick={() => setIsReplying(!isReplying)} className="text-neon-pink hover:text-neon-blue">
              Reply
            </button>
            <button onClick={() => setIsCollapsed(!isCollapsed)} className="text-neon-pink hover:text-neon-blue ml-2">
              {isCollapsed ? 'Show' : 'Hide'} Replies
            </button>
          </div>
        </div>
      </div>
      {isReplying && (
        <div className="mt-2 ml-4">
          <textarea
            value={replyContent}
            onChange={(e) => setReplyContent(e.target.value)}
            className="w-full p-2 bg-hacker-gray text-white border border-neon-green rounded text-sm"
            placeholder="Write your reply..."
            rows="3"
          />
          <button
            onClick={handleReply}
            className="mt-2 bg-neon-pink text-hacker-black px-3 py-1 rounded text-xs hover:bg-neon-blue transition-colors"
            disabled={replyContent.trim() === ''}
          >
            Submit Reply
          </button>
        </div>
      )}
      {!isCollapsed && comment.replies && comment.replies.length > 0 && (
        <div className="mt-2">
          {comment.replies.map((reply) => (
            <Comment
              key={reply._id}
              comment={reply}
              onReply={onReply}
              onUpvote={onUpvote}
              level={level + 1}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function CommentSection({ post, onAddComment, onUpvoteComment }) {
  const { user } = useAuth();
  const [commentSort, setCommentSort] = useState('highestRated');
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [newComment, setNewComment] = useState('');

  const sortedComments = useMemo(() => {
    if (!post || !post.comments) return [];
    const sortComments = (comments) => {
      return comments.sort((a, b) => {
        if (commentSort === 'new') {
          return new Date(b.createdAt || 0) - new Date(a.createdAt || 0);
        } else {
          return (b.upvotes?.length || 0) - (a.upvotes?.length || 0);
        }
      }).map(comment => ({
        ...comment,
        replies: comment.replies && Array.isArray(comment.replies) ? sortComments(comment.replies) : []
      }));
    };
    return sortComments(post.comments);
  }, [post, commentSort]);

  const handleAddComment = (parentCommentId = null, content = newComment) => {
    if (content.trim() === '') return;
    onAddComment(parentCommentId, content);
    setNewComment('');
    setShowCommentBox(false);
  };

  return (
    <section className="mt-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold text-neon-green">Comments</h2>
        <div className="flex items-center">
          <select
            value={commentSort}
            onChange={(e) => setCommentSort(e.target.value)}
            className="bg-hacker-gray text-white border border-neon-green rounded p-1 text-sm mr-2"
          >
            <option value="highestRated">Highest Rated</option>
            <option value="new">New</option>
          </select>
          {user && (
            <button
              onClick={() => setShowCommentBox(!showCommentBox)}
              className="bg-neon-pink text-hacker-black px-3 py-1 rounded text-sm hover:bg-neon-blue transition-colors"
            >
              Add Comment
            </button>
          )}
        </div>
      </div>

      {showCommentBox && (
        <form onSubmit={(e) => { e.preventDefault(); handleAddComment(); }} className="mb-4">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            className="w-full p-2 bg-hacker-gray text-white border border-neon-green rounded text-sm"
            placeholder="Add a comment..."
            rows="3"
          ></textarea>
          <button 
            type="submit" 
            className="mt-2 bg-neon-pink text-hacker-black px-3 py-1 rounded text-sm hover:bg-neon-blue transition-colors"
            disabled={newComment.trim() === ''} 
          >
            Submit Comment
          </button>
        </form>
      )}

      <div className="space-y-4">
        {sortedComments.map((comment) => (
          <Comment
            key={comment._id}
            comment={comment}
            onReply={handleAddComment}
            onUpvote={onUpvoteComment}
          />
        ))}
      </div>
    </section>
  );
}

export default CommentSection;