// ContentList.jsx
import React, { useState, useEffect, useCallback, useRef } from 'react';
import ContentItem from './ContentItem';

function ContentList({ content, isLoading, error, loadMore, hasMore, category, sortBy, setSortBy }) {
  const [viewMode, setViewMode] = useState(() => localStorage.getItem('viewMode') || 'grid');
  const observer = useRef();

  const lastContentElementRef = useCallback(node => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        loadMore();
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore, loadMore]);

  useEffect(() => {
    localStorage.setItem('viewMode', viewMode);
  }, [viewMode]);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
  }, [category, sortBy]);

  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          className="bg-hacker-gray text-neon-blue border border-neon-green rounded p-2 text-xs"
        >
          <option value="hot">Hot</option>
          <option value="highestVoted">Highest Voted</option>
          <option value="newest">Newest</option>
        </select>
        <div>
          <button
            onClick={() => setViewMode('grid')}
            className={`mr-2 text-xs ${viewMode === 'grid' ? 'text-neon-green' : 'text-neon-blue'}`}
          >
            Grid
          </button>
          <button
            onClick={() => setViewMode('list')}
            className={`text-xs ${viewMode === 'list' ? 'text-neon-green' : 'text-neon-blue'}`}
          >
            List
          </button>
        </div>
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className={viewMode === 'grid' ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4' : 'space-y-4'}>
        {content.map((item, index) => (
          <div key={item._id} ref={index === content.length - 1 ? lastContentElementRef : null}>
            <ContentItem item={item} viewMode={viewMode} />
          </div>
        ))}
      </div>
      {isLoading && <div className="text-center py-4 text-neon-blue">Loading...</div>}
      {!hasMore && content.length > 0 && <div className="text-center py-4 text-neon-blue"></div>}
    </div>
  );
}

export default ContentList;