// contexts/AuthContext.js

import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { loginUser, verifyToken, updateUsername as apiUpdateUsername } from '../services/api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const checkToken = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const userData = await verifyToken(token);
        setUser(userData);
      } catch (error) {
        console.error('Token verification failed:', error);
        localStorage.removeItem('token');
        setUser(null);
      }
    } else {
      setUser(null);
    }
    setLoading(false);
  }, []);

  // useEffect(() => { 
  //   checkToken(); // No longer need to call checkToken here
  // }, [checkToken]); 

  const login = async (token) => { // Accept the token directly
    try {
      localStorage.setItem('token', token);
      const userData = await verifyToken(token); // Assuming you have a verifyToken function
      setUser(userData); 
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
  };

  const updateUser = (newUserData) => {
    setUser(prevUser => ({ ...prevUser, ...newUserData }));
  };

  const updateUsername = async (newUsername) => {
    try {
      const updatedUser = await apiUpdateUsername(user._id, newUsername);
      updateUser(updatedUser);
      return updatedUser;
    } catch (error) {
      console.error('Failed to update username:', error);
      throw error;
    }
  };

  const value = {
    user,
    setUser: updateUser,
    login,
    logout,
    loading,
    checkToken, 
    updateUsername
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;