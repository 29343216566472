// components/LoginButton.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function LoginButton() {
  const navigate = useNavigate();
  const { user, login, logout } = useAuth();

  const handleLogin = () => {
    window.location.href = `https://seal-app-tc6dw.ondigitalocean.app/api/auth/google`;
  };

  useEffect(() => {
    // Extract token from URL after redirect
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      localStorage.setItem('token', token);
      login(token); // Call login with the token directly
      navigate('/');  // Optionally navigate to a different page
    }
  }, [login, navigate]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <>
      {user ? (
        <button
          onClick={handleLogout}
          className="text-white px-4 py-2 rounded hover:bg-neon-blue hover:text-hacker-black transition-colors"
        >
          Logout
        </button>
      ) : (
        <button
          onClick={handleLogin}
          className="text-white px-4 py-2 rounded hover:bg-neon-blue hover:text-hacker-black transition-colors"
        >
          Login
        </button>
      )}
    </>
  );
}

export default LoginButton;