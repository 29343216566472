import React from 'react';

function InfoPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <img 
        className="border max-h-[70vh]" // Set max height to 70vh (70% of viewport height)
        src="/logo.png" 
        alt=""
      />

      <div className="max-w-6xl mx-auto flex justify-between items-center pb-4 pt-2">
        <p className="text-xs sm:text-sm text-neon-blue italic">
          For business inquiry contact: brainiac13371@gmail.com
        </p>
      </div>
    </div>
  );
}

export default InfoPage;